.SearchMap {
  .cluster.cluster--bounce {
    animation: clusterGrow 0.75s ease infinite;
    div {
      transition: background-color, font-size 0.75s ease;
      font-size: 16px !important;
      background-color: #af0d40;
      border-radius: 100%;
      border: 1px solid #870d3c;
    }
  }

  @keyframes clusterGrow {
    from {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }

  .Schedule {
  }

  // when using a theme, MuiTableRow-root becomes MuiTableRow-root-xxx
  [class^="MuiTableRow-root"]:last-of-type {
    [class^="MuiTableCell-root"] {
      border: transparent;
    }
  }
  .clamp-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .clamp-lines--2 {
    -webkit-line-clamp: 2;
  }
  .clamp-lines--3 {
    -webkit-line-clamp: 3;
  }
}

// SM down
@media (max-width: 959.95px) {
  // @todo @jorge may have to adjust taking in account the top padding like you did for the laptop layout
  .SearchMap {
    height: 100%;
  }
  .SearchMap__left {
    height: 100%;
  }
  .SearchMap__left__box {
    height: 100%;
  }
}

.SearchMap__loader {
  position: absolute;
  top: calc(50% - 2.5em);
  left: calc(50% - 2.5em);
  width: 5em;
  height: 5em;
  border: 0em solid rgba(0, 0, 0, 0);
  border-left: 0.2em solid #e9426d;
  border-bottom: 0.2em solid #e9426d;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  &:focus {
    outline: none;
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.UserListGridContainer {
  height: 100%;
  > * {
    height: 100% !important;
  }
}

.gm-style {
  .gm-style-iw-d {
    overflow: hidden !important;
    border-radius: 8px !important;
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
  }

  .gm-ui-hover-effect {
    margin-top: 10px !important;
    margin-right: 10px !important;
  }
}
