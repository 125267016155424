.UserListGrid {
  height: 100% !important;
  overflow: initial !important;
}

.UserListGrid .clamp-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
